import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=dfaadf48&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"
import style0 from "./Footer.vue?vue&type=style&index=0&id=dfaadf48&prod&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MailchimpInput: require('/codebuild/output/src930045601/src/onvocado-landing/components/MailchimpInput.vue').default,Footer: require('/codebuild/output/src930045601/src/onvocado-landing/components/global/Footer.vue').default})
